.pr {
  display: inline-block;
  width: 1em;
  height: 1em;
  stroke-width: 0;
  stroke: currentColor;
  fill: currentColor;
}

/* ==========================================
Single-colored icons can be modified like so:
.pr-name {
  font-size: 32px;
  color: red;
}
========================================== */

.pr-spaces {
  width: 1.001953125em;
}

.pr-sidebar-remove {
  width: 1.001953125em;
}

.pr-pencil6 {
  width: 1.001953125em;
}

.pr-gif2 {
  width: 1.001953125em;
}

.pr-add_file {
  width: 1.001953125em;
}

.pr-settings {
  width: 1.001953125em;
}

.pr-sidebar-switch {
  width: 1.3125em;
}

.pr-kanban {
  width: 0.9501953125em;
}

.pr-pinterest {
  width: 1.0009765625em;
}

.pr-gif {
  width: 1.2666015625em;
}
